<!--
  - Copyright 2014-2018 the original author or authors.
  -
  - Licensed under the Apache License, Version 2.0 (the "License");
  - you may not use this file except in compliance with the License.
  - You may obtain a copy of the License at
  -
  -     http://www.apache.org/licenses/LICENSE-2.0
  -
  - Unless required by applicable law or agreed to in writing, software
  - distributed under the License is distributed on an "AS IS" BASIS,
  - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  - See the License for the specific language governing permissions and
  - limitations under the License.
  -->

<template>
  <LineChart
    label="timestamp"
    :datasets="datasets"
    :config="config"
    :data="data"
  />
</template>

<script>
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import LineChart from '@/views/instances/details/LineChart';

export default {
  components: { LineChart },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    return { ...props, t };
  },
  data() {
    return {
      datasets: {
        live: {
          label: this.t('instances.details.threads.live'),
        },
        daemon: {
          label: this.t('instances.details.threads.daemon'),
        },
      },
      config: {
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                title: function (ctx) {
                  return ctx[0].parsed.y;
                },
                label: function (ctx) {
                  return ctx.dataset.label;
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                callback: (label) => {
                  return moment(label).format('HH:mm:ss');
                },
              },
            },
          },
        },
      },
    };
  },
};
</script>
