<!--
  - Copyright 2014-2018 the original author or authors.
  -
  - Licensed under the Apache License, Version 2.0 (the "License");
  - you may not use this file except in compliance with the License.
  - You may obtain a copy of the License at
  -
  -     http://www.apache.org/licenses/LICENSE-2.0
  -
  - Unless required by applicable law or agreed to in writing, software
  - distributed under the License is distributed on an "AS IS" BASIS,
  - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  - See the License for the specific language governing permissions and
  - limitations under the License.
  -->

<template>
  <LineChart
    :config="config"
    :data="data"
    :datasets="datasets"
    label="timestamp"
  />
</template>

<script>
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
import { useI18n } from 'vue-i18n';

import LineChart from '@/views/instances/details/LineChart';

export default {
  components: { LineChart },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    return { ...props, t };
  },
  data() {
    return {
      chart: undefined,
      label: 'timestamp',
      datasets: {
        used: {
          label: 'instances.details.memory.used',
        },
        metaspace: {
          label: 'instances.details.memory.metaspace',
        },
        committed: {
          label: 'instances.details.memory.committed',
        },
      },
      config: {
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                title: (ctx) => {
                  return prettyBytes(ctx[0].parsed.y);
                },
                label: (ctx) => {
                  return this.t(ctx.dataset.label);
                },
              },
            },
          },
          scales: {
            y: {
              ticks: {
                callback: (label) => {
                  return prettyBytes(label);
                },
              },
            },
            x: {
              ticks: {
                callback: (label) => {
                  return moment(label).format('HH:mm:ss');
                },
              },
            },
          },
        },
      },
    };
  },
};
</script>
