<template>
  <hr class="sba-dropdown-divider" />
</template>

<style scoped>
.sba-dropdown-divider {
  height: 0;
  margin: 0.25rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
</style>
